<header id="header" class="">
    <div class="container d-flex align-items-center">

        <a routerLink="/" class="logo me-auto mt-2"><img src="assets/img/manasa.png" alt=""></a>

        <div class="button text-center align-items-center">
            <a class="btn-get-started" (click)="signOut()">Sign Out</a>
        </div>
    </div>
</header>
<app-blog-form></app-blog-form>
<footer id="footer">
<div class="container">
    <div class="copyright">
      &copy; 2021 Rotaract Club of University of Moratuwa. All Rights Reserved.
    </div>
</div>
</footer>