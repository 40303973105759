import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('active') active: string;
  isMobileMenu = false;
  userAuth: boolean;

  constructor(
    private router: Router,
    public auth: AuthService
  ) {
    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.isMobileMenu = false;
        }
      },
      error: (err) => console.log(err)
    });
  }

  ngOnInit(): void {
    this.userAuth = this.auth.authState;
  }

  

  async signIn(){
    await this.auth.signInWithGoogle()
    .then(() => this.router.navigate(['/']))
    .catch( err => {
      alert(err);
    })
  }

  async signOut(){
    await this.auth.signOut()
    .then(() => this.router.navigate(['/']))
    .catch(err => {
      alert(err);
    })
  }

  

}
