import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  pagination: number = 11;
  // blog: any[] = [];
  constructor(
    private fireservices:AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  createNewBlog(record)
  {
    return this.fireservices.collection('blog').add(record);
  }

  uploadImage(file: File) {
    return this.storage.ref('blog-cards/' + Date.now().toString()).put(file);
  }

  retrieve_blogs(blogs: any[]){
    if(blogs.length === 0) {
      return this.fireservices.collection<any>('blog', ref => ref
        .orderBy('timeStamp','desc')
        .limit(this.pagination)
      ).get();
    }

    const lastBlog = blogs[blogs.length - 1];

    return this.fireservices.collection<any>('blog', ref => ref
      .where('timeStamp', '<', lastBlog.timeStamp)
      .orderBy('timeStamp','desc')
      .limit(this.pagination)
    ).get();
  }
}
