import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { User } from './user.model';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token = 'rota_manasa_token';
  user$: Observable<User>;
  userId: string = null;
  authState = false;

  constructor(
    private afs: AngularFirestore, 
    private router: Router,
    public afAuth: AngularFireAuth
  ) 
    {
      this.afAuth.onAuthStateChanged(
        (user) => {
          if (user) {
            this.authState = true;
            this.userId = user.uid;
            this.user$ = this.afs.collection('users').doc<User>(user.uid).valueChanges();
          } else {
            this.userId = null;
            this.authState = false;
            this.user$ = of({
              email: null,
              uid: null
            } as User);
          }
        },
        (err) => console.log(err),
        () => console.log('Completed')
      );
    }

  async signInWithGoogle(): Promise<any> {
    const signInResult = await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    this.afs.collection('users').doc<User>(signInResult.user.uid).snapshotChanges().subscribe(res => {
      return this.updateUser(signInResult.user, res.payload);
    });
  }

  private updateUser(user: firebase.User, snap?: DocumentSnapshot<User>): Promise<void> {
    let userData: User;
    const firebaseUserData = (({uid, email, displayName, photoURL}) => ({uid, email, displayName, photoURL}))(user);

    
    if (snap && snap.exists) {
      userData = snap.data() as User;
      userData = {
        ...userData,
        ...firebaseUserData
      };
    } else {
      userData = {
        ...firebaseUserData,
        isAdmin: false,
      };
    }
    this.authState = true;
    this.setToken(user.uid);
    return this.afs.collection('users').doc<User>(user.uid).set(userData, { merge: true });
  }

  async signOut(): Promise<boolean> {
    this.afAuth.signOut();
    this.authState = false;
    this.clearToken();
    return this.router.navigate(['/']);
  }

  login(username: string) {
    return this.afs.collection<any>('admin-users').doc(username).get();
  }

  setToken(token: string) {
    localStorage.setItem(this.token, token);
  }

  getToken() {
    return localStorage.getItem(this.token);
  }

  clearToken() {
    localStorage.clear();
  }

  isAdmin() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }
}
