<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row justify-content-center align-items-center">

        <div class="col-lg-3 col-md-6 text-center">
          <div class="footer-info">
            <img src="../../../assets/img/manasa.png" alt="" width="40%" class="mt-3">
            <p> <br> 
              <strong>Email:</strong> manasa.rotaract@gmail.com<br>
            </p>
            <div class="social-links mt-3">
              <a href="mailto:manasa.rotaract@gmail.com"><i class='bx bx-envelope'></i></a>
              <a href="https://manasa-blog.medium.com/" class="linkedin"><i class="bx bx-globe"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-6 col-md-6 team my-3">
              <div class="card shadow">
                <img src="../../../assets/img/racuom.png" alt="" width="100%">
                <div class="team-hover-effects">
                  <div class="team-social-icon">
                    <a href="mailto:admin@rotaractmora.org"><i class='bx bx-envelope'></i></a>
                    <a href="https://rotaractmora.org/" class="web"><i class="bx bx-globe"></i></a>
                    <a href="https://www.facebook.com/rotaractmora" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="https://www.instagram.com/rotaractmora/" class="instagram"><i class="bx bxl-instagram"></i></a>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-6 team my-3">
              <div class="card shadow">
                <img src="../../../assets/img/achievers-logo.png" alt="" width="100%">
                <div class="team-hover-effects">
                  <div class="team-social-icon">
                    <a href="mailto:rotaract.achievers@gmail.com"><i class='bx bx-envelope'></i></a>
                    <a href="http://www.rotaractachievers.org/" class="web"><i class="bx bx-globe"></i></a>
                    <a href="https://www.facebook.com/rotaract.achievers" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="https://www.instagram.com/rotaract.achievers/" class="instagram"><i class="bx bxl-instagram"></i></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; 2021 Rotaract Club of University of Moratuwa. All Rights Reserved.
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/medicio-free-bootstrap-theme/ -->
      <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
    </div>
  </div>
</footer>