<section id="contact" class="contact">
    <div class="container">

        <div class="section-title">
            <h2>How to get in touch</h2>
            <p>Fill the contact details requested below and your matter briefly and we will get in touch with you shortly.<br>
                <i>** All details with will be kept with high confidentiality **</i></p>
        </div>

    </div>

    <div class="container">

        <div class="row mt-5 align-items-center justify-content-center">

            <div class="col-lg-6">

                <div class="row">
                    <div class="col-md-12 ">
                        <div class="info-box">
                            <img src="../../../assets/img/question.png" alt="" width="45%">
                            <h3>Email Us</h3>
                            <p>manasa.rotaract@gmail.com<br>For submit articles: articlesmanasa@gmail.com</p>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="info-box mt-4">
                            <img src="../../../assets/img/question.png" alt="" width="45%">
                            <h3>Email Us</h3><br>
                            <p>info@example.com<br>contact@example.com</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box mt-4">
                            <i class="bx bx-phone-call"></i>
                            <h3>Call Us</h3>
                            <p>+1 5589 55488 55<br>+1 6678 254445 41</p>
                        </div>
                    </div> -->
                </div>

            </div>

            <div class="col-lg-6">
                <form (submit)="onSubmitHandler($event)" role="form" class="php-email-form">
                    <div class="row">
                        <div class="col-sm-6 form-group">
                            <input type="text" name="name" class="form-control" [(ngModel)]="formData.name" id="name" placeholder="Your Name" required>
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="email" class="form-control" [(ngModel)]="formData.email" name="email" id="email" placeholder="Your Email" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="formData.subject" name="subject" id="subject" placeholder="Contact" required>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" [(ngModel)]="formData.msg" name="message" rows="5" placeholder="Your Problem" required></textarea>
                    </div>
                    <div class="my-3">
                        <div class="loading" [style.display]="loading ? 'block' : 'none'">Loading</div>
                        <div class="error-message" [style.display]="(!loading && error != '') ? 'block' : 'none'">{{ error }}</div>
                        <div class="sent-message" [style.display]="(!loading && success) ? 'block' : 'none'">Your message has been sent. Thank you!</div>
                    </div>
                    <div class="text-center"><button type="submit" class="btn" [disabled]="formData.name == '' || formData.email == '' || formData.msg == '' || formData.subject == '' " >Send Message</button></div>
                </form>
            </div>

        </div>

    </div>
</section>