import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  blog = [];
  loading = true;

  constructor(public blogRetrieveService: BlogService) { }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.blogRetrieveService.retrieve_blogs(this.blog).subscribe({
      next: (data) => {
        this.blog = data.docs.map(doc => {
          return {
            id: doc.id,
          ...doc.data()
          };
        });
        console.log(this.blog);
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  loadMore() {
    this.blogRetrieveService.retrieve_blogs(this.blog).subscribe({
      next: (data) => {
        this.blog.push(...data.docs.map(doc => {
          return {
            id: doc.id,
          ...doc.data()
          };
        })) 
        console.log(this.blog);
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
}

