<!-- <app-navbar></app-navbar> -->
<header id="header" class="">
    <div class="container d-flex align-items-center">

        <a routerLink="/" class="logo me-auto mt-2"><img src="assets/img/manasa.png" alt=""></a>
        <nav id="navbar" class="navbar order-last order-lg-0">
        </nav>
    </div>
</header>
<div class="container login text-center align-items-center">
    <div class="card shadow text-center  align-items-center">
        <div class="content px-5 pt-5 pb-3 justify-content-center text-center">
            <div class="section-title">
                <h2>Manasa | Admin</h2>
            </div>
            <input type="text" placeholder="Enter the username" class="form-control my-3" [(ngModel)]="username">
            <input type="password" placeholder="Enter the password" class="form-control my-3" [(ngModel)]="password" (keydown) = "handleOnKeyDown($event)">
        </div>
        <div class="button text-center align-items-center pb-3">
            <a class="btn-get-started" (click)="signInWithToken()">Sign In</a>
        </div>
    </div>
</div>