
<app-navbar [active]="'home'"></app-navbar>
<!-- <app-carousel></app-carousel> -->
<div class="container">
    <div class="header-img align-items-center text-center">
        <img src="../../../assets/img/11098.png" alt="" width="80%">
    </div>
    <div class="desc py-3 mt-3 mb-5 text-center shadow">
        <h2>Welcome to <span>Manasa</span></h2>
        <p>“Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.”
            ― <strong>Roy T. Bennett</strong></p>
        <div class="button text-center align-items-center">
            <a href="#about" class="btn-get-started">Read More</a>
        </div>
    </div>

</div>
<app-about-us></app-about-us>
<!-- <app-resource-person></app-resource-person> -->
<app-contact-us></app-contact-us>
<!-- <app-team></app-team> -->
<app-footer></app-footer>