import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResourcePersonContainerComponent } from './container/resource-person/resource-person.component';
import { BlogComponent } from './container/blog/blog.component';
import { HomeComponent } from './container/home/home.component';
import { PodcastComponent } from './container/podcast/podcast.component';
import { BlogadminComponent } from './container/admin/blogadmin/blogadmin.component';
import { AdminLoginComponent } from './Container/admin-login/admin-login.component';
import { AdminGuard } from './services/guards/admin.guard';
import { DashboardComponent } from './container/admin/dashboard/dashboard.component';
import { AboutUsComponent } from './component/about-us/about-us.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'podcast',
    component: PodcastComponent
  },
  {
    path: 'our-partners',
    component: ResourcePersonContainerComponent
  },
  {
    path: 'blog-cards',
    component: BlogadminComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'admin-login',
    component: AdminLoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'about',
    component: AboutUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
