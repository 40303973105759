<div class="loading" *ngIf="loading">
  <div class="d-flex vw-100 my-5 align-items-center justify-content-center">
    <div>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</div>
<div class="band mb-5" *ngIf="!loading">
  <div class="item-{{ i+1 }}" *ngFor="let item of blog; let i = index;">
    <a [href]="item.link" target="_blank" class="card">
      <div *ngIf="item.imgUrl !== ''">
        <div class="thumb" [ngStyle]="{backgroundImage:'url(' + item.imgUrl + ')'}" ></div>
      </div>
      <div *ngIf="item.imgUrl === ''">
        <div class="thumb" [ngStyle]="{backgroundImage:'url(https://picsum.photos/seed/' + item.id + ( i === 0 ? '/700/400' : '/300/200') + ')'}"></div>
      </div>
      <article>
        <h1>{{ item.title }}</h1>
        <span>{{ item.desc.length > 100 ? (item.desc.slice(0, 100) + '...') : item.desc }}</span>
      </article>
    </a>
  </div>
</div>
<div class="form-group text-center">
  <button type="button" class="btn"
    (click)="loadMore()">Load More</button>
</div>