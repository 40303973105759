import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { HomeComponent } from './container/home/home.component';
import { BlogComponent } from './container/blog/blog.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { CarouselComponent } from './component/carousel/carousel.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { FooterComponent } from './component/footer/footer.component';
import { ResourcePersonComponent } from './component/resource-person/resource-person.component';
import { TeamComponent } from './component/team/team.component';
import { PodcastComponent } from './container/podcast/podcast.component';
import { ResourcePersonContainerComponent } from './container/resource-person/resource-person.component';
import { environment } from 'src/environments/environment';
import { CardComponent } from './component/card/card.component';
import { BlogadminComponent } from './container/admin/blogadmin/blogadmin.component';
import { BlogFormComponent } from './component/blog-form/blog-form.component';

import { BlogService } from './services/blog.service';
import { AdminLoginComponent } from './Container/admin-login/admin-login.component';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './container/admin/dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    HomeComponent,
    BlogComponent,
    NavbarComponent,
    CarouselComponent,
    AboutUsComponent,
    FooterComponent,
    ResourcePersonComponent,
    TeamComponent,
    PodcastComponent,
    ResourcePersonContainerComponent,
    CardComponent,
    BlogadminComponent,
    BlogFormComponent,
    AdminLoginComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    HttpClientModule
  ],
  providers: [
    BlogService,
    CardComponent,
    BlogadminComponent,
    BlogFormComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
