// tslint:disable: deprecation
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  formData = {
    name: '',
    email: '',
    subject: '',
    msg: ''
  };

  loading = false;
  success = false;
  error = '';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmitHandler(event) {
    this.loading = true;
    event.preventDefault();

    const htmlMsg = `<h1>New message arrived from Manasa web</h1><br />
    <table>
      <tr><td>From</td><td>: ${this.formData.name} <${this.formData.email}></td></tr>
      <tr><td>Subject</td><td>: ${this.formData.subject}</td></tr>
    </table><br />
    <p>${this.formData.msg}</p>`;

    this.http.post('https://us-central1-manusathhanda.cloudfunctions.net/sendMail', {
      from: `${this.formData.name} <${this.formData.email}>`,
      // to: 'Ranul Navojith <ranulnavoijith@gmail.com>',
      to: 'Manasa Admin <manasa.rotaractmora@gmail.com>',
      // cc: 'Priyan Jerome <priyanjerome96@gmail.com>',
      subject: `Message from Manasa web - ${this.formData.name}`,
      html: htmlMsg
    }).subscribe({
      next: () => {
        this.success = true;
      }, error: (err) => {
        console.log(err);
        this.error = err;
      },
      complete: () => {
        this.loading = false;
        this.formData.email = this.formData.msg = this.formData.name = this.formData.subject = '';
      }
    });
  }


}
