<div class="container mt-5 " style="padding-top: 50px;">
  <div class="mr-5 ml-5">
    <div class="form-group">
      <label for="formGroupExampleInput">Title</label>
      <input type="text" class="form-control" id="formGroupExampleInput" [(ngModel)]="blogTitle" placeholder="Title">
    </div>
    <div class="form-group">
      <label for="formGroupExampleInput">Link to the blog</label>
      <input type="text" class="form-control" id="formGroupExampleInput" [(ngModel)]="blogLink" placeholder="Link">
    </div>
    <div class="form-group">
      <label for="formGroupExampleInput2">Description</label>
      <textarea class="form-control" id="gettoknow" rows="3" [(ngModel)]="blogDescription"
        placeholder="Description"></textarea>
    </div>
    <div class="form-group">
      <label for="exampleFormControlFile1">Upload an Image</label><br>
      <input type="file" #fileinput style="display: none;" class="form-control-file" [value]="''"
        (change)="selectImage($event.target.files[0])" id="exampleFormControlFile1" accept="image/*">
      <button type="button" class="btn" (click)="fileinput.click()">Select an Image</button>&nbsp;{{ file ? file.name :
      ''}}
    </div>
    <div class="form-group">
      <button type="button" [disabled]="!blogTitle || !blogLink || !blogDescription " class="btn"
        (click)="CreateRecord()">Upload</button>&nbsp;{{ progress }}
    </div>
  </div>


</div>