import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { audit } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blogadmin',
  templateUrl: './blogadmin.component.html',
  styleUrls: ['./blogadmin.component.css']
})
export class BlogadminComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  signOut(){
    this.auth.clearToken();
    this.router.navigate(['/']);
  }

}
