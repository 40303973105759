<section id="about" class="about">
  <div class="container" data-aos="fade-up">

    <div class="section-title" id="about-disc">
      <h2>About Us</h2>
      <p style="text-align: justify;">The mind is very powerful, but also so fragile that it takes seconds to break.
        Alone in a crowded place, happiness is now easy to fake.
        Mental health is as important as your life bonds with friends, family, loved ones, your studies, occupation and
        almost anything. Here's the young undergraduates of Rotaract Mora presenting මනස, the first ever university
        based virtual consultancy forum on the Importance of Mental Health through factual webinars & noteworthy
        mentoring sessions.
        Experts and leading persons in the field will guide you to build up an overture for a better change!Every
        undergraduate faced different situations which affect their mental stability. In order to help them
        map-out their own pathway throughout their journey and find the desired destination with a sound mind ,we at
        Manasa, have gathered people who can help them achieve this goal.</p>
    </div>
  </div>
</section>


<section id="faq" class="about">
  <div class="container" data-aos="fade-up">
    <div class="section-title" id="about-disc">
      <h2>FAQ</h2>
    </div>
    <div class="row">
      <div class="col-lg-6 pt-4 pt-lg-0 content order-lg-1 order-2" data-aos="fade-right">
        <div id="faq" class="faq section-bg px-3" style="background-color: rgb(48, 179, 240, 0.05);">
          <ul class="faq-list">

            <li>
              <div data-bs-toggle="collapse" class="collapsed question" href="#faq1">What is Manasa? <i
                  class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq1" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Manasa is a platform that aims at uplifting the lives of University students, by assisting them to
                  identify and solve
                  their mental health issues.
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq2" class="collapsed question">Do I have to provide any personal
                details to the website? <i class="bi bi-chevron-down icon-show"></i><i
                  class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq2" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Absolutely No! You might have to provide certain details to the counseling partner/partners you might
                  contact, but that’s beyond our concern.
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq3" class="collapsed question">What do our Partners
                offer? <i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq3" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Our partners offer counseling services (free / paid) to clients. All you have to do is contact one of
                  our partners based on your choice and speak to them regarding your problem.
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq4" class="collapsed question">How to contact our
                Partners? <i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq4" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Please use the contact details provided in the partner profiles which can be found in the “Our
                  Partners” page.
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq5" class="collapsed question">Who are our Counseling Partners and
                Crisis Support Line Partners? <i class="bi bi-chevron-down icon-show"></i><i
                  class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq5" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Our counseling partners are Deep Haven Counseling and Shanthi Maargam and Our Crisis Support Line
                  Partner is Sri Lanka Sumithrayo.
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq6" class="collapsed question">What is contained in the blog? <i
                  class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq6" class="collapse" data-bs-parent=".faq-list">
                <p>
                  The blog consists of beautifully penned articles that give out a specific message with regards to
                  mental health. Have a look and find it yourself!
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq7" class="collapsed question">Could I contribute to the Blog? <i
                  class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq7" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Yes you can! You can pen one or more articles (Essays / Short Stories / Poems etc.) based on mental
                  health and email it to the address given below. <br>
                  articlesmanasa@gmail.com
                </p>
              </div>
            </li>

            <li>
              <div data-bs-toggle="collapse" href="#faq8" class="collapsed question">How do I report any bugs or
                inconveniences caused by the Website? <i class="bi bi-chevron-down icon-show"></i><i
                  class="bi bi-chevron-up icon-close"></i></div>
              <div id="faq8" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Go to the homepage and scroll down until you see “HOW TO GET IN TOUCH” and enter valuable feedback
                  which we are always ready to accept.
                </p>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div class="col-lg-6 order-lg-2 order-1" data-aos="fade-left">
        <img src="../../../assets/img/about-us.png" class="img-fluid" alt="">
      </div>
    </div>

  </div>
</section>