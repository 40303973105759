import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resource-person',
  templateUrl: './resource-person.component.html',
  styleUrls: ['./resource-person.component.css']
})
export class ResourcePersonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
