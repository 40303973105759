import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  password:string = '';
  username:string = '';
  admin: any[];
  constructor(public authservice: AuthService, private router: Router) { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    if(this.authservice.getToken()){
      this.router.navigate(['/add-blogs']);
    }
  }

  signInWithToken() {
   this.authservice.login(this.username).toPromise().then((res) => {
    if(res.exists){
      if(res.data().token === this.password){
        this.authservice.setToken(this.password);
        this.router.navigate(['/add-blogs']);
      }else{
        alert("Incorrect password");
      }
    }else{
      alert("Incorrect Username");
    }
   }).catch( (err) => {
    console.log(err);
    alert("Something went wrong");
   })
  }

  handleOnKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      // console.log("wada")
      this.signInWithToken();
    }
  }
}
