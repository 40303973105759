import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.css']
})
export class BlogFormComponent implements OnInit {
  // blog: string;
  blogTitle: string;
  blogDescription: string;
  blogLink: string;
  file: File = null;
  featured = false;

  progress = '';
  intervalRef = null;

  constructor(public BlogService: BlogService) { }

  ngOnInit(): void {
  }

  async CreateRecord() {
    let url = '';

    try {
      if (this.file) {
        this.progress = 'Uploading';
        this.startUploading();

        const uploadTask = await this.BlogService.uploadImage(this.file);
        url = await uploadTask.ref.getDownloadURL();

        clearInterval(this.intervalRef);
        this.progress = '';
      }

      await this.BlogService.createNewBlog({
        title: this.blogTitle,
        desc: this.blogDescription,
        link: this.blogLink,
        imgUrl: url,
        timeStamp: Date.now()
      });

      this.clearForm();
      alert('Blog card has been succesfully uploaded');
    } catch (err) {
      console.log(err);
    }
  }

  selectImage(f: File) {
    this.file = f;
  }

  startUploading() {
    this.intervalRef = setInterval(() => {
      if (this.progress.includes('....')) {
        this.progress = 'Uploading';
      } else {
        this.progress += '.';
      }
    }, 500);
  }

  clearForm() {
    this.blogTitle = this.blogDescription = this.blogLink = '';
    this.file = null;
    this.featured = false;
  }
}
