import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resource-person-container',
  templateUrl: './resource-person.component.html',
  styleUrls: ['./resource-person.component.css']
})
export class ResourcePersonContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth'});
  }

}
