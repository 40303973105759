<section id="doctors" class="doctors section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Our Partners</h2>
      <p>Three professional institutes are here to help and guide every undergraduate.</p>
    </div>

    <div class="row justify-content-center">

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch text-center">
        <div class="member" data-aos="fade-up" data-aos-delay="100">
          <div class="member-img" style="height: 74%;">
            <img src="../../../assets/img/DeepHavenLogoFull (1).png" class="img-fluid" alt="">
            <!-- <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div> -->
          </div>
          <div class="member-info">
            <h4>Deep Haven Councelling</h4>
            <!-- <span>Chief Medical Officer</span> -->
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch text-center">
        <div class="member err" data-aos="fade-up" data-aos-delay="200">
          <div class="member-img" style="height: 74%;">
            <img src="../../../assets/img/SL Sumithrayo.png" class="img-fluid" alt="" width="50%">
            <!-- <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div> -->
          </div>
          <div class="member-info">
            <h4>Sri Lanka Sumithrayo</h4>
            <!-- <span>Anesthesiologist</span> -->
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div class="member" data-aos="fade-up" data-aos-delay="300">
          <div class="member-img" style="height: 74%;">
            <img src="../../../assets/img/Shanthi Margam Logo.png" width="416px" class="img-fluid" alt="">
            <!-- <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div> -->
          </div>
          <div class="member-info">
            <h4>Shanthi Maargam</h4>
            <!-- <span>Cardiology</span> -->
          </div>
        </div>
      </div>
      <!-- 
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member" data-aos="fade-up" data-aos-delay="400">
            <div class="member-img">
              <img src="assets/img/doctors/doctors-4.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Amanda Jepson</h4>
              <span>Neurosurgeon</span>
            </div>
          </div>
        </div> -->

    </div>
    <div class="button text-center align-items-center">
      <a href="" class="btn-get-started" routerLink="/our-partners">view More</a>
    </div>
  </div>
</section>