// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDsVpDMyO2PQgVUCemNg4x0oKdL_uvM1Tk",
    authDomain: "rota-manasa.firebaseapp.com",
    projectId: "rota-manasa",
    storageBucket: "rota-manasa.appspot.com",
    messagingSenderId: "832773096876",
    appId: "1:832773096876:web:e808981af246a2650e2609",
    measurementId: "G-TR1XXDZC6P"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
