<header id="header" class="">
  <div class="container d-flex align-items-center">

    <a routerLink="/" class="logo me-auto mt-2"><img src="assets/img/manasa.png" alt=""></a>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <h1 class="logo me-auto"><a href="index.html">Medicio</a></h1> -->

    <nav id="navbar" class="navbar order-last order-lg-0" [class.navbar-mobile]="isMobileMenu">
      <ul>
        <li><a class="nav-link scrollto {{ active === 'home' ? 'active' : ''}}" routerLink="/">Home</a></li>
        <li><a href="#about">About us</a></li>
        <li><a class="nav-link scrollto {{ active === 'blog' ? 'active' : ''}}" routerLink="/blog">Blog</a></li>
        <li><a href="#faq">faq</a></li>
        <li><a href="#contact">Contact us</a></li>
        <!-- <li><a class="nav-link scrollto {{ active === 'dashboard' ? 'active' : ''}}" routerLink="/dashboard">Dashboard</a></li> -->
        <!-- <li><a class="nav-link scrollto" href="#doctors">Doctors</a></li>
          <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
          </li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li> -->
        <li>
          <div class="container my-3">
            <div class="col-md-12 text-center">
              <div *ngIf="!auth.authState">

                <!-- <a class="appointment-btn scrollto" (click)="signIn()" style="color: white; font-weight: 300; "><span
                class="d-none d-md-inline">Sign In With Google</span></a> -->
                <button type="button" class="btn btn-primary" (click)="signIn()" style="background-color: #1290cb; font-size: 0.9rem;">Sign In With Google</button>

              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="container my-3">
            <div class="col-md-12 text-center">
              <div *ngIf="auth.authState">
                <!-- <a class="appointment-btn scrollto" (click)="signOut()"><span class="d-none d-md-inline">Sign Out</span></a> -->
                <button type="button" class="btn btn-primary" (click)="signOut()" style="background-color: #1290cb; font-size: 0.9rem;">Sign Out</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <i class="bi mobile-nav-toggle" [class.bi-list]="!isMobileMenu" [class.bi-x]="isMobileMenu"
        (click)="isMobileMenu = !isMobileMenu"></i>
    </nav>



  </div>
</header>