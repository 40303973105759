<app-navbar [active]="'resPerson'"></app-navbar>
<div class="container mb-5">
    <div class="section-title">
        <h2 style="font-size:235%;">Our Partners</h2>
    </div>
    <div class="content">
        <div class="row mb-5  align-items-center">
            <div class="col-md-4 order-1 order-md-1" data-aos="fade-right">
                <div class="image">
                    <img src="../../../assets/img/DeepHavenLogoFull (1).png" width=100% alt="">
                </div>
            </div>
            <div class="col-md-8 order-2 order-md-2" data-aos="fade-left">
                <div class="card shadow p-3">
                    <div class="section-title">
                        <h2>Deep Haven Counseling</h2>
                    </div>
                    <div class="desc pb-3">
                        <p>At Deep Haven Counseling we strive to provide an authentic counseling service and psychological therapies for the betterment of the mental health of our society. We aim towards enabling our society to be self-aware, resilient and
                            to become the best version of themselves. Additionally, we provide trainings and workshops for the professionals in the field to expand their knowledge and further enhance their skills
                        </p>
                    </div>
                    <div class="contact-info">
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p>Contact Numbers:</p>
                            </div>
                            <div class="col-md-8">
                                <p> 011 7 517 817 <br>(Weekdays: 9.30 am - 5.30 pm)</p>
                                <p> 077 9 939 945 / 077 0 166 819 <br> (Emergency WhatsApp Contacts)</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p> Address:</p>
                            </div>
                            <div class="col-md-8">
                                <p>No. 366/1 Galle Road, Impressions Spaces, Colombo 3.</p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-4 contact-head">
                                <p>Social Media Pages:</p>
                            </div>
                            <div class="col-md-8">
                                <a href=" https://www.instagram.com/deephavencounseling/">
                                    https://www.instagram.com/deephavencounseling/</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5 mb-5  align-items-center">
            <div class="col-md-8 order-2 order-md-1" data-aos="fade-right">
                <div class="card shadow p-3">
                    <div class="section-title">
                        <h2>Shanthi Maargam</h2>
                    </div>
                    <div class="desc pb-3">
                        <p>Founded in 2016, Shanthi Maargam provides support for children and adolescents to improve their emotional well-being with the goal of breaking the cycle of violence in communities. Our youth center, located in Colombo, offers one-on-one
                            confidential counselling services free of charge and conducts sessions with children from the surrounding community to help them cope with personal issues they may be experiencing
                        </p>
                    </div>
                    <div class="contact-info">
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p>Contact Numbers:</p>
                            </div>
                            <div class="col-md-8">
                                <p>071 763 9898</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p> Address:</p>
                            </div>
                            <div class="col-md-8">
                                <p>No 109/12, 1st Lane, Gothami Road, Borella.</p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-4 contact-head">
                                <p>Social Media Pages:</p>
                            </div>
                            <div class="col-md-8">
                                <a href=" https://shanthimaargam.org">https://shanthimaargam.org</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
                <div class="image">
                    <img src="../../../assets/img/Shanthi Margam Logo.png" width=100% alt="">
                </div>
            </div>

        </div>

        <div class="row mt-5 mb-5 align-items-center">
            <div class="col-md-4 order-1 order-md-1 text-center" data-aos="fade-right">
                <div class="image">
                    <img src="../../../assets/img/SL Sumithrayo.png" width=50% alt="">
                </div>
            </div>
            <div class="col-md-8 order-2 order-md-2 " data-aos="fade-left">
                <div class="card shadow p-3">
                    <div class="section-title">
                        <h2>Sri Lanka Sumithrayo</h2>
                    </div>
                    <div class="desc pb-3">
                        <p>We wish to introduce ourselves as Sri Lanka Sumithrayo, a Government approved charity, whose main objective is the prevention of suicide, was founded by the late Mrs. Joan De Mel and a few like minded people in 1974. SLS is a registered
                            charity, incorporated by an Act of Parliament, No. 10 of 1986. The organization has grown over the last 45 years to include 10 befriending centers situated Islandwide, a rural suicide prevention programme that works in over
                            120 villages in the most suicide prone provinces in our country and an outreach unit whose volunteers visit hospitals, elders homes, disability homes, etc in Colombo and the suburbs. SLS is run by unpaid volunteers and our
                            services are provided free of charge.
                        </p>
                        <p>
                            SLS also offers emotional support to those who are lonely, depressed, despairing and in danger of taking their own lives. A team of volunteers dedicated to suicide prevention are ready to offer their help. SLS is an affiliate of Befrienders World Wide,
                            which is present in 32 countries spanning 5 continents and also is an active member of the International Association for Suicide Prevention ( IASP ). Of the various services rendered, we find that regular home visits to traumatized
                            families in suicide prone villages and fostering a helpful and friendly relationship with distressed families, is very helpful in discouraging suicide behavior. We are glad to inform you that suicides have reduced by over 50
                            to 75 % in the villages that we work.
                        </p>
                    </div>
                    <div class="contact-info">
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p>Contact Numbers:</p>
                            </div>
                            <div class="col-md-8">
                                <p>2682535 / 2682570 / 2674436 / 0767520620
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 contact-head">
                                <p> Address:</p>
                            </div>
                            <div class="col-md-8">
                                <p>60/7 Horton Place, Colombo 7</p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-4 contact-head">
                                <p>Social Media Pages:</p>
                            </div>
                            <div class="col-md-8">
                                <a href=" www.srilankasumithrayo.lk">www.srilankasumithrayo.lk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>